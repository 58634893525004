/**
 * This utility function is used to construct modules.
 * It replaces our previous implementations of classes in JS (JS.Class and Class)
 *
 * It automatically calls the init method on a module (object) once the whole document is loaded.
 * It uses jQuery's document.ready method to achieve this.
 *
 * Usage:
 *
 * import module from 'utils/module.js'
 *
 * var myModule = module({
 *  init: function() {
 *    console.log('this function will be called after the document is ready (DOM is fully loaded)')
 *  },
 *
 *  anotherMethod: function() {
 *    ...
 *  }
 * })
 */

// TODO: jQuery should come from a npm package not from a global variable
/* global $ */
function moduleWrapper(module) {
  $(function() {
    if (module.init) {
      module.init()
    }
  })

  return module
}

export default moduleWrapper
