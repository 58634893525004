function franchise() {
  window.$("#franchise_franchisee_account_ids").selectize({
    plugins: ["remove_button"],
    valueField: "id",
    labelField: "company_name",
    searchField: "company_name",
    placeholder: "Company Name",
    load: function(query, callback) {
      if (!query.length) return callback()
      return window.$.ajax({
        url: "/admin/accounts.json",
        type: "GET",
        data: {
          name: query
        },
        error: function() {
          return callback()
        },
        success: function(res) {
          return callback(res)
        }
      })
    }
  })
}
export default franchise
