import $ from "jquery"
import { accountUrl } from "../../../utils/window_utils"

export function bindInboxIndex() {
  $(".js-archive-submit").on("click", function(e) {
    e.preventDefault()
    if (window.SelectAllStore.getState().checkboxGroups.conversations) {
      setUrl("/inbox/conversations/archive")
      setIds()
      submitForm()
    }
  })

  $(".js-unarchive-submit").on("click", function(e) {
    e.preventDefault()
    if (window.SelectAllStore.getState().checkboxGroups.conversations) {
      setUrl("/inbox/conversations/unarchive")
      setIds()
      submitForm()
    }
  })

  $(".js-mark-unread-submit").on("click", function(e) {
    e.preventDefault()
    if (window.SelectAllStore.getState().checkboxGroups.conversations) {
      setUrl("/inbox/conversations/mark_unread")
      setIds()
      submitForm()
    }
  })

  $(".js-mark-read-submit").on("click", function(e) {
    e.preventDefault()
    if (window.SelectAllStore.getState().checkboxGroups.conversations) {
      setUrl("/inbox/conversations/mark_read")
      setIds()
      submitForm()
    }
  })

  window.SelectAllStore.subscribe(function() {
    const checked =
      typeof window.SelectAllStore.getState().checkboxGroups.conversations !==
        "undefined" &&
      window.SelectAllStore.getState().checkboxGroups.conversations.length > 0
    $(
      ".js-mark-read-submit, .js-unarchive-submit, .js-mark-unread-submit, .js-archive-submit"
    ).prop("disabled", !checked)
  })

  function setUrl(url) {
    if (window.location.href.indexOf("self_service") > -1) {
      url = "/self_service" + url
    }
    $("#conversation_ids_form").prop("action", accountUrl(url))
  }
  function setIds() {
    $("#conversation_ids_form")
      .find("#ids")
      .val(window.SelectAllStore.getState().checkboxGroups.conversations)
  }
  function submitForm() {
    $("#conversation_ids_form").submit()
  }
}

export default bindInboxIndex
