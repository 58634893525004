/* global google */

/**
 * This file was migrated from app/assets/javascripts/controllers/concerns/customer_address.js
 * It is used to enter the customer address on the customer form, calendar pages, job and opportunities pages, etc.
 */

import { parseAddressComponents } from "../../../utils/googleMaps"

// TODO: jQuery should come from the jquery package instead of the global instance
const $ = window.$
// import $ from "jquery"

const customerAddress = {
  autocomplete: null,
  initialAddress: null,
  latitude: null,
  longitude: null,
  placeId: null,
  map: null,
  marker: null,

  onLoad(options) {
    this.initialAddress = options.address
    this.latitude = options.latitude
    this.longitude = options.longitude
    this.placeId = options.place_id

    if (options.modal === true) {
      if (typeof google === "undefined") {
        $.getScript(
          "https://maps.googleapis.com/maps/api/js?key=" +
            options.mapKey +
            "&libraries=places"
        )
      }

      $(window).on("shown.bs.modal", () => {
        // TODO: need to delay this for the dispatch map for some reason, trying to figure out why. @tamouse Tue Oct 30 13:10:04 2018
        this.initAutoComplete()
      })
    }

    if (options.modal === false) {
      $.getScript(
        "https://maps.googleapis.com/maps/api/js?key=" +
          options.mapKey +
          "&libraries=places&callback=Concerns.customer_address.initAutoComplete"
      )
    }
  },

  initAutoComplete() {
    if ($("#customer_service_address_line_1").attr("readonly") !== "readonly") {
      this.autocomplete = new google.maps.places.Autocomplete(
        document.getElementById("customer_service_address_line_1"),
        {
          types: ["geocode"],
          /**
           * We need to specify the fields we need. Otherwise, we'll be billed more
           * @see: https://developers.google.com/maps/documentation/places/web-service/usage-and-billing
           */
          fields: ["address_component", "geometry", "place_id"]
        }
      )

      $("#customer_service_address_line_1").keydown(() => {
        $(".pac-container").css("z-index", "10000")
      })
      this.autocomplete.addListener("place_changed", () => this.fillAddress())
    }

    const options = {}

    if (this.latitude && this.longitude) {
      options.center = new google.maps.LatLng(this.latitude, this.longitude)
      options.zoom = 14
    }

    this.map = this.buildMap(options)

    if (options.center) {
      this.setMapMarker(this.latitude, this.longitude)
      this.setMapCenter(this.latitude, this.longitude)
    }

    // Add event listeners to latitude and longitude fields to automatically recenter map after they are edited.
    $("#customer_latitude").change(() => {
      const latitude = Number($("#customer_latitude").val())
      const longitude = Number($("#customer_longitude").val())

      this.resetButtonStatus()
      this.setMapMarker(latitude, longitude)
      this.setMapCenter(latitude, longitude)
    })

    $("#customer_longitude").change(() => {
      const latitude = Number($("#customer_latitude").val())
      const longitude = Number($("#customer_longitude").val())

      this.resetButtonStatus()
      this.setMapMarker(latitude, longitude)
      this.setMapCenter(latitude, longitude)
    })

    $("#reset-geocode-button").click(() => {
      const latitude = Number($("#customer_street_latitude").val())
      const longitude = Number($("#customer_street_longitude").val())

      $("#customer_latitude").val(latitude)
      $("#customer_longitude").val(longitude)
      $("#reset-geocode-button").prop("disabled", true)

      this.setMapMarker(latitude, longitude)
      this.setMapCenter(latitude, longitude)
    })
  },

  setMapMarker(latitude, longitude) {
    if (isNaN(latitude) || isNaN(longitude)) {
      return
    }

    const position = new google.maps.LatLng(latitude, longitude)

    if (this.marker) {
      this.marker.setPosition(position)
    } else {
      this.marker = new google.maps.Marker({
        map: this.map,
        draggable: true,
        position
      })

      google.maps.event.addListener(this.marker, "dragend", event => {
        $("#customer_latitude").val(event.latLng.lat())
        $("#customer_longitude").val(event.latLng.lng())
        this.resetButtonStatus()
      })

      $("#drag-copy").show()
    }
  },

  setMapCenter(latitude, longitude) {
    if (isNaN(latitude) || isNaN(longitude)) {
      return
    }

    const position = new google.maps.LatLng(latitude, longitude)

    this.map.setCenter(position)
    this.map.setZoom(14)
  },

  fillAddress() {
    new Promise(resolve => {
      const place = this.autocomplete.getPlace()
      resolve(place)
    }).then(place => {
      const parsedAddress = parseAddressComponents(place.address_components)

      const address1 =
        (parsedAddress["street_number"] || "") +
        " " +
        (parsedAddress["route"] || "")

      const latitude = place.geometry.location.lat()
      const longitude = place.geometry.location.lng()
      const placeId = place.place_id
      const currentDate = new Date()

      $("#customer_service_address_line_1").val(address1)
      $("#customer_latitude").val(latitude)
      $("#customer_longitude").val(longitude)
      $("#customer_street_latitude").val(latitude)
      $("#customer_street_longitude").val(longitude)
      $("#customer_place_id").val(placeId)
      $("#customer_place_id_retrieved_at").val(currentDate.toISOString())
      $("#customer_service_city").val(parsedAddress["locality"])
      $("#customer_service_state").val(
        parsedAddress["administrative_area_level_1"]
      )
      $("#customer_service_zip_code").val(parsedAddress["postal_code"])
      $("#customer_service_country").val(parsedAddress["country"])

      this.setMapMarker(latitude, longitude)
      this.setMapCenter(latitude, longitude)
    })
  },

  resetButtonStatus() {
    if (
      $("#customer_street_latitude").val() &&
      $("#customer_street_longitude").val() &&
      ($("#customer_longitude").val() !==
        $("#customer_street_longitude").val() ||
        $("#customer_latitude").val() !== $("#customer_street_latitude").val())
    ) {
      $("#reset-geocode-button").prop("disabled", false)
    } else {
      $("#reset-geocode-button").prop("disabled", true)
    }
  },

  buildMap(mapOptions) {
    const styles = [
      {
        featureType: "landscape",
        stylers: [
          { hue: "#FFBB00" },
          { saturation: 43.400000000000006 },
          { lightness: 37.599999999999994 },
          { gamma: 1 }
        ]
      },
      {
        featureType: "road.highway",
        stylers: [
          { hue: "#FFC200" },
          { saturation: -61.8 },
          { lightness: 45.599999999999994 },
          { gamma: 1 }
        ]
      },
      {
        featureType: "road.arterial",
        stylers: [
          { hue: "#FF0300" },
          { saturation: -100 },
          { lightness: 51.19999999999999 },
          { gamma: 1 }
        ]
      },
      {
        featureType: "road.local",
        stylers: [
          { hue: "#FF0300" },
          { saturation: -100 },
          { lightness: 52 },
          { gamma: 1 }
        ]
      },
      {
        featureType: "water",
        stylers: [
          { hue: "#0078FF" },
          { saturation: -13.200000000000003 },
          { lightness: 2.4000000000000057 },
          { gamma: 1 }
        ]
      },
      {
        featureType: "poi",
        stylers: [
          { hue: "#00FF6A" },
          { saturation: -1.0989010989011234 },
          { lightness: 11.200000000000017 },
          { gamma: 1 }
        ]
      }
    ]

    const defaultOptions = {
      scrollwheel: false,
      zoom: 3,
      center: new google.maps.LatLng(39.5, -98.35), // US
      styles: styles
    }

    const map = new google.maps.Map(document.getElementById("radius_map"), {
      ...defaultOptions,
      ...mapOptions
    })

    return map
  }
}

export default customerAddress
