/**
 * This file was migrated from app/assets/javascripts/modules/customer_snapshot_store
 * It's a redux store mainly used in the CustomerSnapshot component.
 * It enables communication between the CustomerSnapshot component and the components that render the payments table/form.
 * When a payment is updated in the Table/Form, the data in this store will be updated and the CustomerSnapshot component will show the new data.
 * It is needed because both components live on different DOM trees. (They are rendered with different react_component calls and so they are virtually 2 different react apps)
 * This can be removed once we replace the whole page with a react component or build some sort of messaging system between components.
 */

import { createStore } from "redux"

const customerSnapshotStore = createStore(
  function(state, action) {
    switch (action.type) {
      case "SET_DATA":
        return { ...state, ...action.data }
      default:
        return state
    }
  },
  {
    opportunities_sum: null,
    jobs_sum: null,
    outstanding_balance: null,
    quickbooks_balance: null,
    payments_sum: null
  }
)

export default customerSnapshotStore
