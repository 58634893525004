/** #1154 & 2269 when a user marks job/s complete then check if there are any
 incomplete work events for job/s. If so then ask the user if they want to
 mark those work events completed also.

 Currently called from jobs show page and jobs index page.

 It works by getting the json for each job and checking if it has any
 incomplete work tasks and stores them in an array 'incompleteWorkTasks'.

 If there are some then ask user if they would like to mark them complete.
 If they do then ajax put for each one marking the task complete.
 */
import SSK from "../../global/ssk"
const $ = window.$

export default (jobs, confirmaction, declineaction) => {
  let incompleteWorkTasks = [],
    jobsModalText = jobs.length > 1 ? "some jobs" : "a job"

  /* this fn returns an array (getJobs) of getJson calls */
  function deferredJobs() {
    let getJobs = []
    $.each(jobs, function(jobindex, job_id) {
      getJobs.push(
        $.getJSON("/api/v2/jobs/" + job_id + ".json", function(data) {
          let job = data
          $.each(job.tasks, function(taskindex) {
            let task = this
            if (!task.completed_at && task.task_type_name === "Work") {
              incompleteWorkTasks.push(task.id)
            }
          })
        })
      )
    })
    return getJobs
  }

  function deferredTasks() {
    let completeTasks = []
    $.each(incompleteWorkTasks, function(incompleteIndex, value) {
      completeTasks.push(
        $.ajax({
          type: "put",
          dataType: "json",
          url: "/tasks/" + value,
          data: {
            task: {
              complete: true
            },
            update_complete: true
          }
        })
      )
    })
    return completeTasks
  }

  function noIncompleteTasks() {
    let body = $("body")
    if (body.hasClass("list-page")) {
      /** if on jobs index page then just submit the form */
      $(".tab-pane.active .batch-update-jobs-form").submit()
    } else if (body.hasClass("jobs")) {
      /** if on jobs show page then show the
       generate invoice? prompt */
      $("#send-invoice").modal("show")
    }
  }

  $.when.apply(null, deferredJobs()).done(function() {
    if (incompleteWorkTasks.length) {
      SSK.lightbox.confirm_action(
        $("#mark_job_complete_modal"),
        "You've completed " + jobsModalText,
        "Do you want to mark all the work events complete as well?",
        "Yes, mark work events complete",
        function(confirmed) {
          if (confirmed) {
            $.when.apply(null, deferredTasks()).done(function() {
              if (typeof confirmaction === "function") {
                confirmaction()
              }
            })
          } else {
            if (typeof declineaction === "function") {
              declineaction()
            }
          }
        }
      )
    } else {
      noIncompleteTasks()
    }
  })
}
