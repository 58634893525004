// File to capture window utilities in a manner that encourages testing
// Initial files from kickserv.js.erb

// accountUrl:  utility to prepend the account_slug to a url
//   if not already present.

export function accountUrl(url) {
  if (window.gon.account_slug) {
    // This would work for urls such as '/slug/' or '/slug?' or '/slug#'
    // not sure if we need all those cases or only need to check for '/slug/'
    // added the other two cases because of the divider check later
    const slug_regex = new RegExp("^/" + window.gon.account_slug + "[/?#]")

    if (url.search(slug_regex) === -1) {
      const divider = url.charAt(0) === "/" ? "" : "/"
      return "/" + window.gon.account_slug + divider + url
    }
  }
  return url
}

// reloadPageToFragment:  function to reload a page to the input
//   fragment location.

export function reloadPageToFragment(fragment) {
  if (fragment.length > 0) {
    fragment = fragment.charAt(0) === "#" ? fragment : "#" + fragment
    window.location.hash = fragment
    window.location.reload(true)
  }
}
