/**
 * This file was migrated from app/assets/javascripts/controllers/concerns/contacts_import_toggle.js
 * It is used on the ContactsImport paloma controllers. (gmail and hotmail actions)
 */

/* ORIGINAL COMMENT: #1313 all employees OR all techs can be shown in a select employee dropdown
   depending on the task type being created/edited */

import $ from "jquery"

const employeesAsOptions = {
  employees: '<option value="">None</option>',
  techs: '<option value="">None</option>',

  set(paramsObj) {
    paramsObj.type = paramsObj.type.toLowerCase()

    const params = { ...paramsObj, target: ".select-technician" }
    const employees = this.employees
    const techs = this.techs

    $(params.target).each(function() {
      const selected = $(this).val()

      $(this)
        .html(
          params.type === "estimate" || params.type === "work"
            ? techs
            : employees
        )
        .val(selected)
    })
  },

  store(type, data) {
    data.forEach(({ employee }) => {
      this[type] +=
        "<option value='" + employee.id + "'>" + employee.name + "</option>"
    })
  }
}

export default employeesAsOptions
