/**
 * This file was migrated from app/assets/javascripts/modules/hour_selector/store.js
 * It's a redux store used in a series of components that display employee hours.
 * It is used in the HoursInput and Hours components that are rendered on different DOM trees.
 * (They are rendered with different react_component calls and so they are virtually 2 different react apps)
 * It is also used in several children components of Hours so the state can be passed as props for that case.
 * Also, the code here is all over the place so it can probably be refactored.
 */

import { createStore } from "redux"
import moment from "moment"
import _ from "lodash"

const hoursStore = createStore(
  function(state, action) {
    if (state === "undefined") {
      var state = {
        hours: {},
        validation_errors: {},
        hours_in_day: [],
        valid: false
      }
    }

    function validatHours() {
      var valid_map = []
      _.each(state.hours, function(val, k) {
        if (typeof state.hours[k] != "undefined" && state.hours[k].length > 0) {
          valid_map.push(true)
        } else {
          valid_map.push(false)
        }
      })
      return _.some(valid_map, Boolean)
    }

    function validateFields(hour_1, hour_2) {
      hour_1 = hour_1.hour() * 60 + hour_1.minutes()
      hour_2 = hour_2.hour() * 60 + hour_2.minutes()
      return hour_1 <= hour_2
    }

    function validateHours(state, day, proposed_hours, hour_set) {
      var end_hour,
        future_hours,
        hour_1,
        hour_2,
        previous_hours,
        proposed_hours,
        start_hour,
        state,
        validate_hours,
        store_state
      var day_hours = state.hours[day]
      var validate_hours = []
      start_hour = moment(proposed_hours[0], ["HH:mm"])
      end_hour = moment(proposed_hours[1], ["HH:mm"])
      previous_hours = day_hours[hour_set - 1]
      future_hours = day_hours[hour_set + 1]
      validate_hours.push(validateFields(start_hour, end_hour))
      if (previous_hours != null) {
        hour_1 = moment(previous_hours[1], ["HH:mm"])
        hour_2 = moment(proposed_hours[0], ["HH:mm"])
        validate_hours.push(validateFields(hour_1, hour_2))
      }
      if (future_hours != null) {
        hour_1 = moment(proposed_hours[1], ["HH:mm"])
        hour_2 = moment(future_hours[0], ["HH:mm"])
        validate_hours.push(validateFields(hour_1, hour_2))
      }

      return validate_hours.every(function(x) {
        return x
      })
    }

    switch (action.type) {
      case "UPDATE_HOURS":
        var assigned_hours = {}
        assigned_hours[action.day] = action.hours ? action.hours : []
        var hours = _.assign(state.hours, assigned_hours)
        var valid = validatHours()
        return _.assign(state, { hours: hours, valid: valid })
      case "ADD_HOURS":
        var hours = state.hours[action.day]
        if (hours.length > 0) {
          var hour_set = state.hours[action.day][hours.length - 1]
          hours = [
            hour_set[1],
            moment(hour_set[1], ["HH:mm"])
              .add(1, "hours")
              .format("HH:mm")
          ]
        } else {
          hours = ["09:00", "17:00"]
        }
        var state_hours = _.clone(state.hours)
        state_hours[action.day].push(hours)
        var valid = validatHours()
        return _.assign(state, { hours: state_hours, valid: valid })
      case "REMOVE_HOUR":
        var key = parseInt(action.key, 10)
        var state_hours = _.clone(state.hours)
        state_hours[action.day].splice(action.key, 1)
        var valid = validatHours()
        return _.assign(state, { hours: state_hours, valid: valid })
      case "UPDATE_HOUR_SET":
        var hours = state.hours[action.day][action.hours_key]
        var proposed_hours = _.clone(hours)
        proposed_hours[parseInt(action.item_key)] = moment(
          action.proposed_hour,
          ["h:mm A"]
        ).format("HH:mm")
        var valid = validateHours(
          state,
          action.day,
          proposed_hours,
          action.hours_key
        )
        if (valid) {
          var hours = _.clone(state.hours)
          hours[action.day][action.hours_key] = proposed_hours
          var errors = {}
          errors[action.day] = null
          var valid = validatHours()
          return _.assign(state, {
            hours: hours,
            validation_errors: errors,
            valid: valid
          })
        }
        {
          var errors = {}
          errors[action.day] = action.hours_key
          _.assign(state, { validation_errors: errors })
        }
      case "GET_HOURS_IN_DAY":
        var am, pm
        var get_hours = function(period) {
          var display_hours, hours, interval
          hours = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]
          hours.unshift(12)
          interval = ["00", "15", "30", "45"]
          display_hours = []
          $.each(hours, function(i, hour) {
            $.each(interval, function(i, interval) {
              display_hours.push(hour + ":" + interval + " " + period)
            })
          })
          return display_hours
        }
        am = get_hours("AM")
        pm = get_hours("PM")
        var hours_in_day = am.concat(pm)
        _.assign(state, { hours_in_day: hours_in_day })
      default:
        return state
    }
  },
  { hours: {}, validation_errors: {}, hours_in_day: [], valid: false }
)

export default hoursStore
