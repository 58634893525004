import $ from "jquery"

function assignments() {
  $(document).on("click", "a#assign_tech", function(e) {
    e.preventDefault()
    let list = $(this).closest(".assign-employee-list"),
      newSelect = list.find("ul#assignments li:first").clone()
    newSelect
      .find("select")
      .removeAttr("id")
      .end()
      .find("select option")
      .removeAttr("selected")
      .end()
    list
      .find("ul#assignments")
      .append(newSelect)
      .end()
      .find("a.delete")
      .show()
    return false
  })

  $(document).on(
    "click",
    "ul#assignments .delete-assignment-list-btn",
    function(e) {
      e.preventDefault()
      if ($("ul#assignments li").length > 1) {
        $(this)
          .parents("li")
          .remove()
      }
      return false
    }
  )
}
export default assignments
