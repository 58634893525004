import $ from "jquery"

function colorpicker(params) {
  const el = params.el,
    hidden_field = params.field,
    text_field = params.text_field,
    preview = params.preview_el

  text_field.on("keyup", function() {
    preview.text($(this).val())
  })

  el.on("click", function() {
    const color = $(this).data("color")
    const attr = $(this).data("attr")
    const change = {}
    change[attr] = "#" + color
    preview.css(change)
    hidden_field.val(color)
  })
}

export default colorpicker
