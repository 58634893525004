/**
 * This file was migrated from app/assets/javascripts/controllers/concerns/job_charge_modal.js
 * It is used in the jobs and opportunities show action, as well as the estimate confirm and invoice confirm actions
 * to control the modal that allows the creation/edition of job charges
 */

// TODO: jQuery should come from the jquery package instead of the global instance
const $ = window.$
// import $ from "jquery"

function jobChargeModal() {
  new window.KickServ.ItemSelect($(".time_entry_autosuggest"))

  $(document).on(
    "keyup",
    "#job_charge_quantity, #job_charge_price_per_unit",
    function() {
      const jobChargeFormRows = $(this).parents(".job_charge_form_row")
      const quantity = parseFloat(
        jobChargeFormRows.find("#job_charge_quantity").val()
      )
      const price = parseFloat(
        jobChargeFormRows.find("#job_charge_price_per_unit").val()
      )
      if (!(isNaN(quantity) || isNaN(price)))
        jobChargeFormRows
          .find(".total-line-total")
          .val((quantity * price).toFixed(2))
    }
  )

  function hideItemError() {
    $("#job_charge_item_id_error").hide()
    $("#new_job_charge_errors").addClass("hide")
  }

  function setFormErrorStatus(jobId) {
    const id = jobId || ""
    const forms = "#new_job_charge, #edit_job_charge_" + id
    $(forms).on("submit", function(e) {
      $(".job_charge_item_id_error").hide()
      const item_description =
        $("#job_charge_item_id").val() || $("#item_name").val()
      if (
        typeof item_description === "undefined" ||
        item_description === "" ||
        item_description === null
      ) {
        $(".job_charge_item_id_error").show()
        resetSubmitJobChargeButton()
        return false
      }
      $(".submit-job-charge-button").addClass("disabled loading")
    })
  }

  function resetSubmitJobChargeButton() {
    $(".submit-job-charge-button").removeClass("disabled loading")
  }

  function addListeners() {
    // Allows the use of the Enter key when in a text area or when focused on the form submit
    // Should only affect the job charge modal and only apply when a single modal
    // is showing (job type modal and recurring job modals can go over this modal)
    $(document).on(
      "keypress",
      ":input:not(textarea):not([type=submit])",
      function(event) {
        if (event.keyCode === 13 && $(".modal.in").length === 1) {
          event.preventDefault()
        }
      }
    )

    // 'Add a new item' requires the lab item_search to be turned
    $("#add-new-charge-item").on("click", function() {
      $(".job_charge_form_row").addClass("warning")
      $(".existing-items-input").addClass("hide")
      $(".new-items-input, #new_item").removeClass("hide")
      $("#job_charge_taxable").prop("checked", false)
      $("#job_charge_taxable").attr("disabled", false)
      $("#job_charge_taxable").show()
      $(".taxable-icon").hide()
      $("#job_charge_details").val("")
      // Unmount and then mount the description/details rich text editor react component
      // as a way to reset the component since its value has been replaced
      window.ReactRailsUJS.unmountComponents("#job-charge-description-editor")
      window.ReactRailsUJS.mountComponents("#job-charge-description-editor")
      // Cleanup other fields since a new item will be added
      $(".time_entry_autosuggest")
        .val(null)
        .trigger("change")
      $("#job_charge_item_id").val("")
      $("#job_charge_quantity").val("")
      $("#job_charge_price_per_unit").val("")
      $(".total-line-total").val("")
      return false
    })

    $("#cancel-new-charge-item").on("click", function() {
      $(".job_charge_form_row").removeClass("warning")
      $(".existing-items-input").removeClass("hide")
      $(".new-items-input, #new_item").addClass("hide")

      $("#job_charge_taxable").hide()
      $("#job_charge_taxable").attr("disabled", true)
      $("#job_charge_taxable").prop("checked", false)
      $(".fa-times.taxable-icon").show()

      return false
    })
  }

  $(".job-charge-section").on("click", ".edit_job_charge", function(e) {
    hideItemError()
    e.preventDefault()
    const jobId = $(this).attr("data-jid")
    $.ajax({
      url: $(this).attr("rel") + ".js",
      type: "get",
      dataType: "html",
      success: function(data) {
        $("#job_charge_modal").html(data)
        new window.KickServ.ItemSelect(
          $(".edit_job_charge .time_entry_autosuggest"),
          {
            parent_container: ".edit_job_charge .billable-time-entry-row",
            price_field: "#time_entry_price_per_unit",
            action: "edit"
          }
        )
        $("#edit-job-charge").modal("show")
        setFormErrorStatus(jobId)
        addListeners()
        $(".autosize").autosize()
        // Remount the description rich text editor react component since the html has been replaced
        window.ReactRailsUJS.mountComponents("#job-charge-description-editor")
      }
    })
  })

  $("#new-job-charge-button, #job-page-add-job-charge-shortcut").click(function(
    e
  ) {
    hideItemError()
    e.preventDefault()
    $.ajax({
      url: $(this).attr("rel") + ".js",
      type: "get",
      dataType: "html",
      success: function(data) {
        $("#job_charge_modal").html(data)
        new window.KickServ.ItemSelect(
          $("#new_job_charge .time_entry_autosuggest"),
          {
            parent_container: "#new_job_charge .billable-time-entry-row",
            price_field: "#time_entry_price_per_unit",
            action: "edit"
          }
        )
        $("#edit-job-charge").modal("show")
        setFormErrorStatus()
        addListeners()
        $("#job_charge_item_id").select2("focus")
        $(".autosize").autosize()
        // Remount the description rich text editor react component since the html has been replaced
        window.ReactRailsUJS.mountComponents("#job-charge-description-editor")
      }
    })
  })

  $(".job-charge-section").on("click", ".delete_job_charge", function() {
    function deleteFunction() {
      $(this)
        .parents(".job_charge")
        .addClass("deleted")
        .find("button")
        .fadeOut()
      $.post($(this).attr("rel"), { _method: "delete" }, function(data) {
        // OMG. We should not have this eval line.
        eval(data)
      })
      return false
    }

    if ($(this).hasClass("recurrable_destroy")) {
      window.SSK.trigger_destroy_recurring_items($(this), "job charge")
    } else {
      window.SSK.lightbox.confirm_delete($(this), deleteFunction, "job charge")
    }

    return false
  })
}

export default jobChargeModal
