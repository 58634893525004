// TODO: jQuery should come from the jquery package instead of the global instance
const $ = window.$
// import $ from "jquery"

const expenses = {
  init() {
    this.toggleJobChargeFields(
      $("#new_expense .billable-expense-row #expense_billable")
    )
    this.initialize_selectize("#new_expense #expense_vendor_id", "vendor")
    this.initialize_selectize(
      "#expense_job_charge_attributes_job_charge_type_id",
      "job_charge_type"
    )

    $("#new_expense").submit(function() {
      $("#expense_form_submit_button")
        .val("Saving")
        .attr("disabled", true)
    })

    $(".expense-section").on("click", "a.cancel-expense-form", function() {
      if ($(this).attr("rel") === undefined) {
        if ($(".expense").length === 0)
          $("#new_expense_section.click-toggle").hide()

        $(this)
          .parents("#new_expense_section.click-toggle")
          .hide()
      } else {
        $(".edit_expense_form").empty()
        $(".listing-expense#expense_" + $(this).data("expense-id")).removeClass(
          "hide"
        )
      }
      return false
    })

    $(".new-expense").on("click", function() {
      expenses.clearFormFields()
    })

    $(".expense-section").on("click", "a.edit_expense", function() {
      const edit_button = this
      let parents = $(this).parents(".listing-expense")
      parents.addClass("hide")
      parents.siblings(".listing-expense").removeClass("hide")
      $.ajax({
        url: $(edit_button).attr("rel") + ".js",
        type: "get",
        dataType: "html",
        success: function(data) {
          $(".edit_expense_form").html(data)

          // Remount the expense charge description editor since the HTML has been replaced outside of React
          window.ReactRailsHelpers.mountComponents(
            "#expense-charge-description-source"
          )
          window.ReactRailsHelpers.mountComponents(
            "#expense-charge-description-editor"
          )

          $(".datepicker-single").pickadate({
            container: "body",
            format: "mmm d yyyy",
            formatSubmit: "mm/dd/yyyy",
            hiddenName: true,
            clear: false
          })
          $(".autosize").autosize()

          new window.KickServ.ItemSelect($(".expense_job_charge_autosuggest"), {
            parent_container: ".billable-expense-row",
            price_field: "#expense_job_charge_attributes_price_per_unit"
          })

          expenses.toggleJobChargeFields(
            $(".edit_expense_form .billable-expense-row #expense_billable")
          )
          expenses.initialize_selectize(
            ".edit_expense_form #expense_vendor_id",
            "vendor"
          )
          expenses.initialize_selectize(
            ".edit_expense_form #expense_job_charge_attributes_job_charge_type_id",
            "job_charge_type"
          )
        }
      })
      return false
    })

    $(document).on(
      "change",
      ".billable-expense-row #expense_billable",
      function() {
        expenses.toggleJobChargeFields($(this))
      }
    )

    $(document).on("keyup", "textarea.expense_description", function(e) {
      const expense = $(this).data("expense") ? $(this).data("expense") : ""
      $("#hidden_expense_details_" + expense).val(e.currentTarget.value)
      $("#hidden_expense_details_" + expense).text(e.currentTarget.value)
      if ($("input#hidden-charge-description-source").val() === "expense") {
        $(
          "input[type=hidden]#expense_job_charge_attributes_details_" + expense
        ).val($("#hidden_expense_details_" + expense).val())
        // Unmount and then mount the description/details rich text editor react component
        // as a way to reset the component since its value has been replaced
        // Unmounting and mounting the same component on each keystroke could be quite expensive
        // but it's the only way to integrate this screen with the rich text editor usign react_rails
        // Since this is legacy code and will soon be converted to React I (@micopc) think this is acceptable
        window.ReactRailsHelpers.remountComponents(
          "#expense-charge-description-editor"
        )
      }
    })
  },

  clearFormFields() {
    // Need to clear out the hidden fields and expense description radios/textareas
    $("#new_expense .expense_job_charge_radio").attr("checked", false)
    $(
      "#new_expense .expense_description, #new_expense .job_charge_description, #new_expense .hidden_expense_value "
    ).val("")
  },

  toggleJobChargeFields(element) {
    $(element)
      .parents(".billable-expense-row")
      .children(".expense-job-charge-fields")
      .find("input")
      .prop("disabled", !element.prop("checked"))
    if ($("#expense_warn_job_charge").length > 0)
      element.prop("checked")
        ? $("#expense_warn_job_charge").addClass("hidden")
        : $("#expense_warn_job_charge").removeClass("hidden")

    // Disable react components inputs too
    window.ReactRailsHelpers.mergeComponentProps(
      "#expense-charge-description-source",
      { disabled: !element.prop("checked") }
    )
  },

  initialize_selectize(element, data_key) {
    $(element).selectize({
      create: function(input, callback) {
        const data_args = {}
        data_args[data_key] = { name: input }
        $.ajax({
          url: $(element).attr("rel"),
          type: "post",
          data: data_args,
          success: function(data) {
            if (data) {
              return callback({ value: data.id, text: input })
            }
          },
          error: function() {
            callback(null)
          }
        })
      },
      sortField: "text"
    })
  },

  fillChargeDescription(expense, source) {
    if (source === "item") {
      $("#use_details_item").prop("checked", true)

      $("#expense_job_charge_attributes_details_" + expense).val(
        $("#hidden_item_details_" + expense).val()
      )
      // Unmount and then mount the description/details rich text editor react component
      // as a way to reset the component since its value has been replaced
      window.ReactRailsHelpers.remountComponents(
        "#expense-charge-description-editor"
      )
    } else if (source === "expense") {
      $("#use_details_expense_" + expense).prop("checked", true)

      $("#expense_job_charge_attributes_details_" + expense).val(
        $("#hidden_expense_details_" + expense).val()
      )
      // Unmount and then mount the description/details rich text editor react component
      // as a way to reset the component since its value has been replaced
      window.ReactRailsHelpers.remountComponents(
        "#expense-charge-description-editor"
      )
    }
  }
}

export default expenses
