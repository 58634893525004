import SSK from "../../global/ssk"
const $ = window.$

export default () => {
  let newTimeEntrySection = $(".time-entry-section")
  newTimeEntrySection.on("submit", "#new_time_entry_form", function() {
    let $this = $(this)
    $(this).ajaxSubmit({
      dataType: "script",
      beforeSubmit: function() {
        $this
          .find("input:submit")
          .val("Adding..")
          .attr("disabled", true)
      }
    })
    return false
  })

  $("#new_time_entry_cancel_link").click(function() {
    $(this)
      .parents(".section")
      .find(".section-content div.click-toggle")
      .fadeToggle()
      .find(":input:visible:enabled:first")
      .focus()
    return false
  })

  $(document).on("click", "a.edit_time_entry", function() {
    let self = this
    let parents = $(self).parents(".listing-time-entry")
    parents.addClass("hide")
    parents.siblings(".listing-time-entry").removeClass("hide")
    $.ajax({
      url: $(self).attr("rel") + ".js",
      type: "get",
      dataType: "html",
      success: function(data) {
        $(".edit_time_entry_form").html(data)
        new KickServ.ItemSelect($(".time_entry_autosuggest"), {
          parent_container: ".billable-time-entry-row",
          price_field: "#time_entry_price_per_unit"
        })
        $(".datepicker-single").pickadate({
          container: "body",
          format: "mmm d yyyy",
          formatSubmit: "mm/dd/yyyy",
          hiddenName: true
        })
        $(".combobox").combobox()
      }
    })
    $("body").scrollTo("#job-time", { duration: 500 })
    return false
  })

  $(document).on("click", "a.delete_time_entry", function() {
    function deleteFunction() {
      $.post($(this).attr("rel"), { _method: "delete" }, function(data) {
        eval(data)
      })
      return false
    }
    SSK.lightbox.confirm_delete($(this), deleteFunction, "time entry")
    return false
  })

  let timeEntrySection = $(".time-entry-section")

  timeEntrySection.on("submit", "form.edit_time_entry_form", function() {
    let $this = $(this)
    $(this).ajaxSubmit({
      dataType: "script",
      resetForm: true,
      beforeSubmit: function() {
        $this
          .find("input:submit")
          .val("Saving..")
          .attr("disabled", true)
      },
      success: function() {
        $this.find("input:submit").val("Update")
      }
    })
    return false
  })

  timeEntrySection.on("click", "#edit_time_entry_cancel_link", function() {
    $(
      ".listing-time-entry#time_entry_" + $(this).data("time-entry-id")
    ).removeClass("hide")
    $(".edit_time_entry_form").empty()
    return false
  })
}
