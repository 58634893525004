/**
 * This is a set of global utilities.
 * It was copied from `app/assets/javascripts/kickserv.js.erb` and should be accessible globally with window.SSK
 * Besides formatting fixes (thanks to Prettier), only minor changes were made like always declare variables with var,
 * use === in comparisons, use window when accessing global variables, etc.
 *
 * Other modules in the application depend on this module and sometimes adds new properties.
 * For example: `SSK.quickbooks` or `SSK.lightbox`
 */

// $ (jQuery), Modernizr, Mousetrap should be imported from npm instead of coming from a global variable
// import $ from 'jquery'
// import Modernizr from 'modernizer'
// import Mousetrap from 'mousetrap'

// intuit is included via script tag. Not sure if there's an npm module that can replace it

// Once jQuery, Modernizr, etc are installed as npm modules instead of via a script/vendor folder,
// the corresponding variable can be removed from the eslint comment below
/* global $, Modernizr, Rails, Mousetrap, intuit, Ladda */
import module from "../utils/module"
import calendarIcon from "../../../images/icons/calendar.png"

const $ = window.$

const SSK = {
  bind_drop_down_calls(item) {
    var mouse_is_inside = false
    item.mouseenter(function() {
      mouse_is_inside = true
    })
    item.mouseleave(function() {
      mouse_is_inside = false
    })

    function close_drop_down() {
      item.fadeOut("fast", function() {
        $(this).removeAttr("style")
      })
      $("body").die("mouseup", mouseup_handler)
      $(item)
        .unbind("mouseenter")
        .unbind("mouseleave")
      $(".main-nav-sub-nav-container.visible").removeClass("visible")
      return false
    }

    function mouseup_handler() {
      if (!mouse_is_inside) {
        close_drop_down()
        return false
      }
    }

    $(document).on("mouseup", "body", mouseup_handler)
  },

  count_matching_elements_between(filter, start_elem, end_elem) {
    return start_elem.nextAll(filter).filter(end_elem.prevAll(filter)).length
  },

  trigger_destroy_recurring_items($this, label) {
    $("#destroy-recurring-items .item-title").html(label)

    SSK.lightbox.trigger_lightbox($("#destroy-recurring-items"))
    var $url =
      $this.attr("href") === "#" ? $this.attr("rel") : $this.attr("href")
    var $association_type = $url.match(/\/(\w*)\/\d*$/)
    var $association_id = $url.match(/\/(\d*)$/)

    // Bind handlers
    $(".destroy_recurring_items").on("click", function() {
      var parent_class = "." + $association_type[1].replace(/s$/, "")

      $($this)
        .parents(parent_class)
        .css("color", "#cccccc")

      $.ajax({
        url:
          "/recurring_items/" +
          $association_id[1] +
          ".js?recurring=" +
          $(this).data("recurring") +
          "&association_type=" +
          $association_type[1],
        type: "DELETE",
        success: function() {
          if (parent_class === ".task") SSK.tasks.remove_task($this)
          else
            $($this)
              .parents(parent_class)
              .fadeOut()
        }
      })
      $("#destroy-recurring-items").modal("hide")
      return false
    })
  },

  click_toggle($this) {
    $($this)
      .parents(".section, .toggle-parent, .panel")
      .find(".section-content div.click-toggle, div.click-toggle")
      .toggle()
      .find(":input:visible:enabled:first")
      .focus()
  },

  click_show($this) {
    $($this)
      .parents(".section, .toggle-parent, .panel")
      .find(".section-content div.click-toggle, div.click-toggle")
      .show()
      .find(":input:visible:enabled:first")
      .focus()
  },

  instantiate_single_view_datepicker() {
    $(".date-single-view").datepicker({
      onSelect: function() {
        $(this).change()
      },
      showOn: "both",
      buttonImage: calendarIcon,
      dateFormat: "mm/dd/yy",
      changeMonth: true,
      changeYear: true,
      buttonImageOnly: true,
      yearRange: "2010:2030",
      numberOfMonths: 1
    })
  },

  instantiate_recurrable_items() {
    // Recurrable Jobs and Items
    $(document).on("click", ".job-save-button, .recurrable_item", function() {
      $.form = $(this).parents("form")
      if ($(this).hasClass("job-save-button")) $(".loading-icon").fadeIn()
      if ($(this).hasClass("hide_only_this_job")) {
        $("#edit-recurring-jobs a[data-recurring='this']")
          .parents(".recurring-job-row")
          .hide()
      }
      SSK.lightbox.trigger_lightbox($("#edit-recurring-jobs"))
      return false
    })
  },

  items_toggle(e) {
    if ($(e).hasClass("plus-icon")) {
      $(e)
        .removeClass("plus-icon")
        .addClass("minus-icon")
        .children("div")
        .html("")
    } else {
      $(e)
        .removeClass("minus-icon")
        .addClass("plus-icon")
        .children("div")
        .html("+")
    }
  },

  init() {
    $(document).ajaxSend(function(event, jqXHR, settings) {
      if (
        window.gon.account_slug !== undefined &&
        window.gon.account_slug !== null &&
        settings.url.indexOf(window.gon.account_slug) === -1 &&
        settings.url.indexOf("mini-profiler-resources") === -1 &&
        settings.url.indexOf("message-bus") === -1
      ) {
        settings.url = "/" + window.gon.account_slug + settings.url
      }
    })

    // global error handling
    $(document).ajaxError(function(event, jqxhr, settings, exception) {
      // eslint-disable-next-line no-console
      console.log(event)
      // eslint-disable-next-line no-console
      console.log(jqxhr)
      // eslint-disable-next-line no-console
      console.log(settings)
      // eslint-disable-next-line no-console
      console.log(exception)
    })

    // Execute the code that was originally outside the SSK class but in the same file
    initializeExtensions()

    $(document).on("click", ".plus-icon, .minus-icon", function() {
      SSK.items_toggle($(this))
      return false
    })

    if (Modernizr.touch) {
      $(document).on("click", ".main-nav-container .main_nav_link", function(
        e
      ) {
        if (
          $(this)
            .closest(".main-nav-container")
            .hasClass("visible")
        ) {
          $(this)
            .closest(".main-nav-container")
            .removeClass("visible")
        } else {
          e.preventDefault()
          $(this)
            .closest(".main-nav-container")
            .addClass("visible")
        }
        SSK.bind_drop_down_calls(
          $(this)
            .closest(".main-nav-container")
            .find(".main-nav-sub-nav")
        )
      })
    }
    $(document).on("click", ".user-nav-container .profile_link", function(e) {
      if (
        $(this)
          .closest(".user-nav-container")
          .hasClass("visible")
      ) {
        $(this)
          .closest(".user-nav-container")
          .removeClass("visible")
      } else {
        e.preventDefault()
        $(this)
          .closest(".user-nav-container")
          .addClass("visible")
      }
      SSK.bind_drop_down_calls(
        $(this)
          .closest(".user-nav-container")
          .find(".main-nav-sub-nav")
      )
    })

    // Recurrable Jobs and Items
    $(document).on("click", ".job-save-button, .recurrable_item", function() {
      $.form = $(this).parents("form")
      if ($(this).hasClass("job-save-button")) $(".loading-icon").fadeIn()
      if ($(this).hasClass("hide_only_this_job")) {
        $("#edit-recurring-jobs a[data-recurring='this']")
          .parents(".recurring-job-row")
          .hide()
      }
      SSK.lightbox.trigger_lightbox($("#edit-recurring-jobs"))
      return false
    })

    $(document).on("click", ".recurring_job_edit", function(e) {
      e.preventDefault()
      if (!$(this).hasClass("loading")) {
        // This is a hack to disable recurring buttons
        // unless the button pressed was for 'Only this job'
        if (!$(this).hasClass("only_this_job_button")) {
          $(".recurring_series_update").removeClass("hide")
          $(".recurring_job_edit").addClass("loading disabled")
          $(".open-job-duration")
            .addClass("loading")
            .attr("disabled", true)
        }
        $.form.attr(
          "action",
          $.form.attr("action").split("?")[0] +
            "?recurring=" +
            $(this).data("recurring")
        )
        let form = $.form[0]
        Rails.fire(form, "submit")
        $("#edit-recurring-jobs a[data-recurring='this']")
          .parents(".recurring-job-row")
          .show()
        $("#edit-recurring-jobs, #edit-job-charge, #job-duration").modal("hide")
      }
      return false
    })

    $(document).on("click", ".cancel-recurring-job-button", function(e) {
      $(".submit-job-charge-button").removeClass("loading disabled")
    })

    // Custom Time Selection
    $(document).on("click touchend", "li.hour-item", function() {
      $(this)
        .parents(".auto-time-suggestion")
        .find("input")
        .val(
          $(this)
            .find("a")
            .html()
        )
    })

    $(".datepicker-single").pickadate({
      container: "body",
      format: "mmm d yyyy",
      formatSubmit: "mm/dd/yyyy",
      hiddenName: true,
      clear: false
    })

    $(".datepicker-report").pickadate({
      container: "body",
      format: "mmm d, yyyy",
      formatSubmit: "yyyy-mm-dd",
      hiddenName: true,
      selectMonths: true,
      selectYears: 30,
      clear: false
    })

    // Report datepickers should have dynamic min/max properties
    if (
      $(".report-results-panel #start_date").length &&
      $(".report-results-panel #end_date").length
    ) {
      var from_input = $("#start_date").pickadate()
      var from_picker = from_input.pickadate("picker")
      var to_input = $("#end_date").pickadate()
      var to_picker = to_input.pickadate("picker")

      // Check for existing to or from dates
      // if ( from_picker.get('value') ) { to_picker.set('min', from_picker.get('select')); }
      // if ( to_picker.get('value') ) { from_picker.set('max', to_picker.get('select')); }

      // Update the to and from dates when one or the other is selected
      from_picker.on("set", function(e) {
        if (e.select) {
          to_picker.set("min", from_picker.get("select"))
        }
      })
      to_picker.on("set", function(e) {
        if (e.select) {
          from_picker.set("max", to_picker.get("select"))
        }
      })
    }

    $(".datepicker-series").pickadate({
      container: "body",
      format: "mmm d, yyyy"
    })

    $(".timepicker-hour").pickatime({
      container: "body",
      format: "h:i A",
      formatSubmit: "H",
      hiddenName: true,
      interval: 60
    })

    $(".timepicker-job").pickatime({
      container: "body",
      format: "h:i A",
      formatSubmit: "HH:i",
      hiddenName: true,
      interval: 15
    })

    $(".autosize").autosize()
    $("#q, #q-ks").popover()
    $(".row-link").click(function() {
      window.document.location = $(this).data("target")
    })

    // Everything below was originally in the same file kickserv.js.erb but was not inside the SSK method
    // It's exactly the sae if we put the code inside the init method.

    // Open external links in a new window
    $("a[rel=external]").attr("target", "_blank")

    $(".section a.click-toggle, .panel a.click-toggle").click(function() {
      SSK.click_toggle(this)
      return false
    })

    $(".section-content a.cancel, .panel a.cancel").click(function() {
      $(this)
        .parents("div.click-toggle")
        .toggle()
      return false
    })

    $("form#merge_customer").submit(function() {
      var winner = $("#winner").val()
      if (winner == null || $.trim(winner) === "") {
        alert("Please complete step #2.")
        return false
      } else {
        if (!window.confirm("Are you sure?")) {
          return false
        }
      }
    })

    SSK.instantiate_single_view_datepicker()
    SSK.instantiate_recurrable_items()

    $("input:hidden[data-datepicker]").livequery(function() {
      $(this).datepicker({
        onSelect: function(datestr) {
          var date = $.datepicker.parseDate("mm/dd/yy", datestr)
          var id = $(this).attr("data-datepicker")

          // format is either job_scheduled_on_1i or scheduled_on[year]
          // There must be a cleaner way to do this - logical || didn't work :(
          var month = $("#" + id + "_2i")
          if (month.length === 0) {
            month = $("#" + id + "_month")
          }
          var day = $("#" + id + "_3i")
          if (day.length === 0) {
            day = $("#" + id + "_day")
          }
          var year = $("#" + id + "_1i")
          if (year.length === 0) {
            year = $("#" + id + "_year")
          }

          month.val(date.getMonth() + 1) // month
          day.val(date.getDate()) // day
          year.val(date.getFullYear()) // year
        },

        showOn: "both",
        buttonImage: calendarIcon,
        changeMonth: true,
        changeYear: true,
        buttonImageOnly: true,
        yearRange: "2010:2030",
        numberOfMonths: 2
      })
    })

    // tagging
    $("a#new_tagging_link, form.tagging_form a.cancel").click(function() {
      $("form.tagging_form")
        .toggle()
        .find("input[type=text]:first")
        .focus()
      $("#new_tagging_link_wrapper").toggle()
      $(".tagging-icon").toggle()
      return false
    })

    // reset iCal links
    $("a#reset_ical_link").click(function() {
      if (window.confirm("Reset iCal links?")) {
        $.post($(this).attr("href"), {}, function(data) {
          // (2020-02-27) Gabriel (micopc): I think the intention here was
          // to reload the page which should be done via window.location.reload()
          window.location = window.location
        })
      }
      return false
    })

    // ajaxify CRUD delete links
    $("body").on("click", "a.crud_delete_link", function() {
      if (
        $(this).attr("data-confirm_msg") &&
        $(this).attr("data-confirm_msg") !== ""
      ) {
        if (!window.confirm($(this).attr("data-confirm_msg"))) {
          return false
        }
      }

      function deleteFunction() {
        var self = this
        var url
        if (SSK.jobs_stuff.getParameterByName("parent_id") !== "") {
          var $id = SSK.jobs_stuff.getParameterByName("parent_id")
          url = $(self).attr("rel") + "?parent_id=" + $id
        } else {
          url = $(self).attr("rel") || window.location.href
        }
        $.post(
          url,
          {
            _method: "delete"
          },
          function(data) {
            if ($(self).attr("data-after_delete_url") !== null)
              window.location = $(self).attr("data-after_delete_url")
            else if ($(self).hasClass("hide-recent-activity"))
              $(self)
                .parents(".recent-activity")
                .remove()
            else if ($(self).hasClass("hide_li"))
              $(self)
                .parents("li:first")
                .remove()
            else if ($(self).hasClass("hide_tr"))
              $(self)
                .parents("tr:first")
                .remove()
            else if (SSK.jobs.getParameterByName("parent_id") !== "") {
              $id = SSK.jobs.getParameterByName("parent_id")
              window.location.pathname = "/customers/" + $id
            } else {
              // trim off record identifer and return to "index" page or refresh page
              // example: /admin/clusters/1 => /admin/clusters
              // example: /account/logo => /account/logo
              var parts = window.location.pathname.match(/^(.*)\/(\d+)/)
              window.location = parts == null ? window.location : parts[1]
            }
          },
          "script"
        )
        return false
      }
      var item_name = $(this).data("item_name") || "item"
      SSK.lightbox.confirm_delete($(this), deleteFunction, item_name)
      return false
    })

    ////////////////////////////////////////////////////////////////////
    // Job page shortcuts to add objects
    $(document).on("click", ".job-page-scroll-to", function(e) {
      e.preventDefault()
      // Show the details tab in case it's not currently active
      $('#job-nav a[href="#details"]').tab("show")
      var id_to_scroll_to = "#" + $(this).data("scrollto")
      var header_offset =
        $("#reach_listings_banner").height() + $("#mobile_app_banner").height()
      $(".job_container").animate(
        { scrollTop: $(id_to_scroll_to).offset().top - header_offset - 65 },
        0
      )
    })

    // These new listeners are only valid for panels that are converted to react.
    // their inner working is different so that's why we create new listeners and not use the legacy one
    // Once the page is converted to React, this can safely be removed and replaced with some other logic.
    $(document).on("click", ".job-page-shortcut", function(e) {
      e.preventDefault()

      // Show the details tab in case it's not currently active
      $('#job-nav a[href="#details"]').tab("show")

      var targetPanel = $(this).data("scrollto")

      // Open the note form
      $(targetPanel + " > header > button").click()

      // Scroll to panel
      var header_offset =
        $("#reach_listings_banner").height() + $("#mobile_app_banner").height()
      $(".job_container").animate(
        { scrollTop: $(targetPanel).offset().top - header_offset - 65 },
        0
      )
    })

    $(document).on("click", "#job-page-add-task-shortcut", function(e) {
      SSK.click_show($("#new_task_section"))
    })

    $(document).on("click", "#job-page-add-expense-shortcut", function(e) {
      SSK.click_show($("#new_expense_section"))
    })

    $(document).on("click", "#job-page-add-time-entry-shortcut", function(e) {
      SSK.click_show($("#new_time_entry_section"))
    })

    // End job page shorcuts
    ////////////////////////////////////////////////////////////////////

    // logout from Intuit on Kickserv logout
    $(document).on("click", "#logout_link", function() {
      intuit.ipp.anywhere.logout()
    })

    /* dialogs */
    $(document).on("click", ".dialog a.cancel", function() {
      $(this)
        .parents(".dialog")
        .dialog("close")
      return false
    })

    // setup
    $("a#hide_setup_page").click(function() {
      if (!window.confirm("Are you sure you want to hide this page?")) {
        return false
      }
    })

    $("a.setup_link, a.cancel", "#setup_steps").click(function() {
      $(this)
        .parents("li")
        .find(".action")
        .toggle()
      return false
    })

    $("a.click_toggle").click(function() {
      $(this)
        .next(".click_toggle")
        .toggle()
      return false
    })

    // Fix the bug with the date pickers z-index
    $(document).on("mouseup", ".ui-datepicker-trigger", function(e) {
      var zIndex = "$('#ui-datepicker-div').css('z-index', '99999');"
      setTimeout(zIndex, 200)
    })

    $(".hide_notification").click(function() {
      $(this).replaceWith(
        '<img src="/assets/spinner.gif" class="right" width=10 />'
      )
      $.ajax({
        url: $(this).data("href"),
        type: "POST",
        data: {
          post_id: $(this).attr("rel")
        },
        dataType: "script"
      })
    })

    $(".input-code").on("click", function() {
      $(this).select()
    })

    $("textarea[maxlength]").maxlength({
      alwaysShow: true,
      appendToParent: true,
      warningClass: "label label-primary",
      limitReachedClass: "label label-danger"
    })
  }
}

// This function executed all code that was inside kickserv.js.erb but outside the main jQuery document.ready function
// It is only grouped inside this function to separate them from the rest of the SSK object.
function initializeExtensions() {
  // jQuery plugin to prevent double submission of forms
  $.fn.preventDoubleSubmission = function() {
    $(this).on("submit", function(e) {
      var $form = $(this)
      if ($form.data("submitted") === true) {
        e.preventDefault()
      } else {
        $form.data("submitted", true)
      }
      //bind everything to the instance since we are using ajaxComplete
      $form.ajaxComplete(function() {
        $form.data("submitted", false)
        $form.unbind("ajaxComplete")
      })
    })

    // Keep chainability
    return this
  }

  $.listen("parsley:form:validate", function(form) {
    form.$element.data("submitted", false)
  })

  $("form").preventDoubleSubmission()
  ;(function($) {
    $.widget("custom.combobox", {
      _create: function() {
        this.wrapper = $("<span>")
          .addClass("custom-combobox")
          .insertAfter(this.element)

        this.element.hide()
        this._createAutocomplete()
        this._createShowAllButton()
      },

      _createAutocomplete: function() {
        var selected = this.element.children(":selected"),
          value = selected.val() ? selected.text() : ""

        this.input = $("<input>")
          .appendTo(this.wrapper)
          .val(value)
          .attr("title", "")
          .addClass(
            "custom-combobox-input ui-widget ui-widget-content ui-state-default ui-corner-left"
          )
          .autocomplete({
            delay: 0,
            minLength: 0,
            source: $.proxy(this, "_source")
          })
          .tooltip({
            tooltipClass: "ui-state-highlight"
          })
        this._on(this.input, {
          autocompleteselect: function(event, ui) {
            ui.item.option.selected = true
            this.element.trigger("change")
            this._trigger("select", event, {
              item: ui.item.option
            })
          },

          autocompletechange: "_removeIfInvalid"
        })
      },

      _createShowAllButton: function() {
        var input = this.input,
          wasOpen = false

        $("<a>")
          .attr("tabIndex", -1)
          .attr("title", "Show All Items")
          .tooltip()
          .appendTo(this.wrapper)
          .button({
            icons: {
              primary: "ui-icon-triangle-1-s"
            },
            text: false
          })
          .removeClass("ui-corner-all")
          .addClass("custom-combobox-toggle ui-corner-right")
          .mousedown(function() {
            wasOpen = input.autocomplete("widget").is(":visible")
          })
          .click(function() {
            input.focus()

            // Close if already visible
            if (wasOpen) {
              return
            }

            // Pass empty string as value to search for, displaying all results
            input.autocomplete("search", "")
          })
      },

      _source: function(request, response) {
        var matcher = new RegExp(
          $.ui.autocomplete.escapeRegex(request.term),
          "i"
        )
        response(
          this.element.children("option").map(function() {
            var text = $(this).text()
            if (this.value && (!request.term || matcher.test(text)))
              return {
                label: text,
                value: text,
                option: this
              }
          })
        )
      },

      _removeIfInvalid: function(event, ui) {
        // Selected an item, nothing to do
        if (ui.item) {
          return
        }

        // Search for a match (case-insensitive)
        var value = this.input.val(),
          valueLowerCase = value.toLowerCase(),
          valid = false
        this.element.children("option").each(function() {
          if (
            $(this)
              .text()
              .toLowerCase() === valueLowerCase
          ) {
            this.selected = valid = true
            return false
          }
        })

        // Found a match, nothing to do
        if (valid) {
          return
        }

        // Remove invalid value
        this.input
          .val("")
          .attr("title", value + " didn't match any item")
          .tooltip("open")
        this.element.val("")
        this._delay(function() {
          this.input.tooltip("close").attr("title", "")
        }, 2500)
        this.input.data("ui-autocomplete").term = ""
      },

      _destroy: function() {
        this.wrapper.remove()
        this.element.show()
      }
    })
  })($)

  $(".combobox").combobox()

  Ladda.bind(".ladda-button")
  $.listen("parsley:field:error", function() {
    Ladda.stopAll()
    $(".ladda-button")
      .removeClass("disabled")
      .prop("disabled", false)
  })

  $.listen("parsley:form:validated", function(e) {
    var $form = e.$element
    if (!$form.parsley().isValid()) {
      $form.data("submitted", false)
    }
  })

  $(document).on("click", ".new_customer_modal", function() {
    SSK.newCustomerModalType = $(this).attr("data-type")
  })

  var hasShownCreditCardError = false
  $(document).on("keyup", "input, textarea", function() {
    var $el = $(this)
    if ($el.attr("data-skip-cc-validation")) {
      return
    }
    var text = $(this).val()
    var matched = text.search(/(\d[\s-]?){16}/)
    if (matched > -1 && !hasShownCreditCardError) {
      hasShownCreditCardError = true
      var error_message =
        "It looks like your text may contain credit card numbers. This information will be redacted when you submit the form."
      $el.popover({
        placement: "bottom",
        container: "body",
        html: true,
        content: error_message
      })
      $el.popover("show")
      $el.on("blur", function() {
        $el.popover("destroy")
      })
    }
  })

  $(document).ready(function() {
    // obey fragment hash?
    // NOTE: this is a hack: the page should scroll down to the fragment, but it isn't always doing that
    // and I don't know why. tamouse 2017-01-04
    var hash = window.location.hash
    if (hash !== "") {
      // need to clear the hash and then set it back so the browser detects a change
      window.location.hash = ""
      window.location.hash = hash
    }
  })
}

export default module(SSK)
