/* global google */

// This function will be replaced in the future with a real version.
// For now just returned the global google variable
export function loadGoogleMapsScript() {
  return new Promise(resolve => {
    resolve(google)
  })
}

export function parseAddressComponents(addressComponents) {
  const formComponents = {
    street_number: "short_name",
    route: "long_name",
    locality: "long_name",
    administrative_area_level_1: "short_name",
    postal_code: "short_name",
    country: "long_name"
  }

  const parsedAddress = {}

  addressComponents.forEach(component => {
    const componentType = component.types[0]
    if (formComponents[componentType]) {
      parsedAddress[componentType] = component[formComponents[componentType]]
    }
  })

  return parsedAddress
}
