/** #1154 check if job can be marked complete, if so then ask the user if they
 want to mark the job complete. */

import SSK from "../../global/ssk"

const $ = window.$

export default (job_id, action) => {
  if (job_id) {
    $.getJSON('/api/v2/jobs/' + job_id + '.json', function (data) {
      let job = data;
      if (!job.completed_on) { // if job has not been marked complete
        let incompleteWorkTasks = 0;
        $.each(job.tasks, function () {
          let task = this;
          if (!task.completed_at && task.task_type_name === "Work") {
            incompleteWorkTasks++;
          }
        });
        if (incompleteWorkTasks === 0) {
          SSK.lightbox.confirm_action(
            $('#mark_job_complete_modal'),
            "You've completed all the work events",
            "Mark the job complete as well?",
            "Yes, mark job complete",
            function (confirmed) {
              if (confirmed) {
                $.ajax({
                  url: '/jobs/' + job_id + '/mark_complete',
                  type: 'PUT',
                  dataType: 'script',
                  success: function (data) {
                    if (typeof (action) === "function") {
                      action();
                    }
                  }
                });
              }
            }
          );
        }
      }
    });
  }
}
