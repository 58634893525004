/**
 * This file was migrated from app/assets/javascripts/controllers/concerns/notes.js
 * It is used in the jobs, opportunities and customer pages
 */

// TODO: jQuery should come from the jquery package instead of the global instance
const $ = window.$
// import $ from "jquery"

function notes() {
  // I don't think this nex few lines are used anymore.
  // While the `edit_note_link` exists in the _note.html.erb partial, I don't think that partial is used.
  // Leaving it here just in case. Also most of the note code was already migrated to react
  $("body").on("click", "a.edit_note_link", function() {
    $.getJSON($(this).attr("rel"), function(data) {
      $(this)
        .parents(".note")
        .find(".edit_note")
        .html(data.form)
        .show()
        .end()
        .find(".listing-note-content")
        .addClass("text-muted")
        .end()
        .find("textarea")
        .focus()
    })

    return false
  })

  // These next lines are not related to notes but rather to attachments.

  $("body").magnificPopup({
    delegate: "a.open-popup",
    tLoading: "Loading attachment #%curr%...",
    mainClass: "mfp-img-mobile",
    gallery: {
      enabled: true,
      navigateByImgClick: true,
      preload: [0, 1] // Will preload 0 - before current, and 1 after the current image
    },
    image: {
      tError: '<a href="%url%">The image #%curr%</a> could not be loaded.',
      titleSrc: function(item) {
        return item.el.attr("title")
      }
    }
  })

  $(".fileupload").fileupload({
    autoUpload: false,
    replaceFileInput: false,
    dropZone: $(".dropzone"),
    maxNumberOfFiles: 1
  })

  $(document).bind("dragover", function(e) {
    let foundDropzone

    const dropZone = $(".dropzone")
    const timeout = window.dropZoneTimeout

    if (!timeout) {
      dropZone.addClass("in")
    } else {
      clearTimeout(timeout)
    }

    let found = false
    let node = e.target

    do {
      if ($(node).hasClass("dropzone")) {
        found = true
        foundDropzone = $(node)
        break
      }

      node = node.parentNode
    } while (node != null)

    dropZone.removeClass("in hover")

    if (found) {
      foundDropzone.addClass("hover")
    }

    window.dropZoneTimeout = setTimeout(function() {
      window.dropZoneTimeout = null
      dropZone.removeClass("in hover")
    }, 100)
  })

  $(document).bind("drop dragover", function(e) {
    e.preventDefault()
  })
}

export default notes
