/**
 * This file was migrated from app/assets/javascripts/modules/select_all/store.js
 * It's used in the checkbox that selects all inbox messages / conversations
 * Specifically, the SelectAll and SelectAllCheckbox react components
 * It's also used in the inbox page to obtain the selected conversation
 * before applying the bulk actions of "Archive", "Mark read" and "Mark Unread"
 * Relevant function: window.bindInboxIndex()
 */

import { createStore } from "redux"

const selectAllStore = createStore(
  function(state = { checkboxGroups: {} }, action) {
    var checkboxGroups

    switch (action.type) {
      case "ADD_TO_GROUP":
        checkboxGroups = { ...state.checkboxGroups }
        if (typeof checkboxGroups[action.group] == "undefined") {
          checkboxGroups[action.group] = []
        }
        checkboxGroups[action.group] = checkboxGroups[action.group].concat(
          action.ids
        )

        return { ...state, checkboxGroups }
      case "CLEAR_GROUP":
        checkboxGroups = { ...state.checkboxGroups }
        if (typeof checkboxGroups[action.group] !== "undefined") {
          delete checkboxGroups[action.group]
        }
        return { ...state, checkboxGroups }
      case "REMOVE_FROM_GROUP":
        checkboxGroups = { ...state.checkboxGroups }
        var group = checkboxGroups[action.group]
        if (typeof group !== "undefined") {
          checkboxGroups[action.group] = group.filter(id => id !== action.id)
        }

        return { ...state, checkboxGroups }
      default:
        return state
    }
  },
  { checkboxGroups: {} }
)

export default selectAllStore
