/**
 * This file was migrated from app/assets/javascripts/controllers/concerns/live_signature_concern.js
 * It is used to collect signatures in the estimate, invoice and work order pages.
 */
/* Original comment follows: */
/* the javascript to handle showing the live signature popup */

// TODO: jQuery should come from the jquery package instead of the global instance
const $ = window.$
// import $ from "jquery"

function getLiveSignature(type, params) {
  let sigPadInit = false

  const $additionalSigInfoForm = $("#additional_signature_info_form")
  const $sigReason = $additionalSigInfoForm.find("#reason")

  const default_reason = params["signature_reason"]

  $("#collect_live_signature_btn").click(function() {
    if (type === "invoice" || type === "work_order") {
      $("#additional_signature_information").modal("show")
    } else {
      showSigModal()
    }
    return false
  })

  $(".submit-signature-info-btn").click(function() {
    $additionalSigInfoForm.parsley().validate()

    if ($additionalSigInfoForm.parsley().isValid()) {
      $("#additional_signature_information").modal("hide")
      showSigModal()
    }

    return false
  })

  function showSigModal() {
    $("#add_signature_modal").modal("show")
  }

  $("#additional_signature_information").on("shown.bs.modal", function() {
    if (default_reason !== undefined || default_reason !== "")
      $("#additional_signature_info_form #reason").val(default_reason)
  })

  $("#add_signature_modal").on("shown.bs.modal", function() {
    $("body").addClass(
      "modal-open"
    ) /* #1897 workaround for body losing this class when transitioning from reason modal */
    $("#signature_document_type").val(params["document_type"])
    $("#signature_signable_id").val(params["document_id"])
    $("#add_signature_modal #signature_reason").val($sigReason.val())
    $(this)
      .find(".submit-signature-btn")
      .prop("disabled", false)
      .text("Save")

    if ($sigReason.val() === "") {
      $("#display_signature_reason").hide()
    } else {
      $("#display_signature_reason")
        .html("<b>Reason</b>: " + $sigReason.val())
        .show()
    }
    if (sigPadInit === false) {
      new window.KickServ.SignatureBox("sig_container", function() {
        $.ajax({
          data: $(".live-signature").serializeArray(),
          dataType: "script",
          type: "POST",
          url: "/signatures"
        })
      })
      sigPadInit = true
    }
  })
}

export default getLiveSignature
