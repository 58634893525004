// NOTE: (Original comments)
// This is almost more reusable by coincidence, it is used in the following views:
// * estimates/estimate.html.erb
// * jobs/estimate.html.erb
// * jobs/invoice.html.erb
// * jobs/work_order.html.erb
// * self_service/jobs/estimate.html.erb
// * self_service/jobs/invoice.html.erb

const $ = window.jQuery
// TODO: Until all the jquery modules have been migrated, the previous line is needed to
// keep using jQuery. Afterward, the following line can be used instead:
// import $ from "jquery"

function print_iframe(options = {}) {
  $(window).load(function() {
    const iframeHeight = $("#print_iframe")
      .contents()
      .height()
    $("#print_iframe").height(iframeHeight)
    $(document).trigger("iframe-loaded")
  })

  $(document).on("iframe-loaded", function(e) {
    // FIXME: this is a very broad selector, when there's only maybe one thing to enable when this event fires. An improvement would be to make a much more focused selector.
    $(".disabled").removeClass("disabled")
  })
}
export default print_iframe
