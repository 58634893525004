/**
 * This file was migrated from app/assets/javascripts/controllers/concerns/job_contacts_select.js
 * It is used on the contact picker ("to" and "cc" field) in the "send email" form for invoices, estimates and work orders
 */

// TODO: jQuery should come from the jquery package instead of the global instance
const $ = window.$
// import $ from "jquery"

function jobContactsSelect() {
  const REGEX_EMAIL =
    "([a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@" +
    "(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?)"

  function item(item, escape) {
    return (
      "<div>" +
      (item.text ? '<span class="name">' + escape(item.text) + "</span>" : "") +
      (item.value
        ? '<span class="email">' + escape(item.value) + "</span>"
        : "") +
      "</div>"
    )
  }

  function option(item, escape) {
    const label = item.text || item.value
    const caption = item.text ? item.value : null

    return (
      "<div>" +
      '<span class="label">' +
      escape(label) +
      "</span>" +
      (caption ? '<span class="caption">' + escape(caption) + "</span>" : "") +
      "</div>"
    )
  }

  function createFilter(input) {
    let match, regex

    // email@address.com
    regex = new RegExp("^" + REGEX_EMAIL + "$", "i")
    match = input.match(regex)
    if (match)
      return !Object.prototype.hasOwnProperty.call(this.options, match[0])

    // name <email@address.com>
    regex = new RegExp("^([^<]*)<" + REGEX_EMAIL + ">$", "i")
    match = input.match(regex)
    if (match)
      return !Object.prototype.hasOwnProperty.call(this.options, match[2])

    return false
  }

  function create(input) {
    return { value: input }
  }

  const selectizeOptions = {
    maxItems: null,
    lockOptgroupOrder: true,
    sortField: [{ field: "text", direction: "asc" }],
    render: {
      item: item,
      option: option
    },
    createFilter: createFilter,
    create: create
  }

  $("#to").selectize(selectizeOptions)
  $("#cc").selectize(selectizeOptions)
}

export default jobContactsSelect
