/* eslint-disable import/first */
/**
 * This pack is meant to contain all global legacy JS
 * The code should be stored in the src/legacy directory and imported here.
 * If a module needs to be global it can be assigned to window here.
 */

// NOTE: This should be removed after we've migrated code, and imported them directly as needed
import {
  accountUrl,
  reloadPageToFragment
} from "../src/legacy/utils/window_utils"
window.accountUrl = accountUrl
window.reloadPageToFragment = reloadPageToFragment
import { bindInboxConversation } from "../src/legacy/global/modules/inbox/conversation_bindings"
window.bindInboxConversation = bindInboxConversation
import { bindInboxIndex } from "../src/legacy/global/modules/inbox/index_bindings"
window.bindInboxIndex = bindInboxIndex

import SSK from "../src/legacy/global/ssk"
window.SSK = SSK

// Assign other modules as properties of SSK here
// ...
// Paloma Concerns
// The following line is needed to keep the currently defined concerns. Once all concerns have been migrated, it can be simplified.
window.Concerns = window.Concerns || {}

import franchise from "../src/legacy/global/concerns/franchise"
window.Concerns.franchise = franchise

import assignments from "../src/legacy/global/concerns/assignments"
window.Concerns.assignments = assignments

import print_iframe from "../src/legacy/global/concerns/print_iframe"
window.Concerns.print_iframe = print_iframe

import batchEditing from "../src/legacy/global/concerns/batch_editing"
window.Concerns.batch_editing = batchEditing

import contactsImport from "../src/legacy/global/concerns/contacts_import"
window.Concerns.contacts_import = contactsImport

import customerAddress from "../src/legacy/global/concerns/customer_address"
window.Concerns.customer_address = customerAddress

import employeeAsOptions from "../src/legacy/global/concerns/employees_as_options"
window.Concerns.employees_as_options = employeeAsOptions

import expenses from "../src/legacy/global/concerns/expenses"
window.Concerns.expenses = expenses

import jobChargeModal from "../src/legacy/global/concerns/job_charge_modal"
window.Concerns.job_charge_modal = jobChargeModal

import jobContactsSelect from "../src/legacy/global/concerns/job_contacts_select"
window.Concerns.job_contacts_select = jobContactsSelect

import getLiveSignature from "../src/legacy/global/concerns/live_signature"
window.Concerns.get_live_signature = getLiveSignature

import notes from "../src/legacy/global/concerns/notes"
window.Concerns.notes = notes

import cookiesConcern from "../src/legacy/global/concerns/cookies"
window.Concerns.cookies = cookiesConcern

import promptCompleteWorkEvents from "../src/legacy/global/concerns/prompt_complete_work_events"
window.Concerns.prompt_complete_work_events = promptCompleteWorkEvents

import timeEntriesStuff from "../src/legacy/global/concerns/time_entries_stuff"
window.Concerns.time_entries_stuff = timeEntriesStuff

import promptCompleteJob from "../src/legacy/global/concerns/prompt_complete_job"
window.Concerns.prompt_complete_job = promptCompleteJob

// Redux stores.
// These need to be global because they are incorporated in multiple react components (not in the same tree) and/or pieces of code.

import customerSnapshotStore from "../src/legacy/redux/customerSnapshotStore.js"
window.CustomerSnapshotStore = customerSnapshotStore

import hoursStore from "../src/legacy/redux/hoursStore"
window.HoursStore = hoursStore

import selectAllStore from "../src/legacy/redux/selectAllStore"
window.SelectAllStore = selectAllStore

import colorpicker from "../src/legacy/global/modules/colorpicker"
window.colorpicker = colorpicker

// Other utilities
// If you migrate vendor modules from the old sprockets JS, then you can make them global here
// These modules should be imported directly in the corresponding JS module once they are migrated to webpack
