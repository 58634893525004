/**
 * This file was migrated from app/assets/javascripts/controllers/concerns/contacts_import_toggle.js
 * It is used on the ContactsImport paloma controllers. (gmail and hotmail actions)
 */
import $ from "jquery"

function contactsImport() {
  $(document).on("click", ".select-all-toggle", function() {
    $(".select-all-toggle")
      .parents()
      .find(".list-group")
      .find('input[type="checkbox"]')
      .prop("checked", isChecked(this))
  })

  function isChecked(checkboxClass) {
    return $(checkboxClass).is(":checked")
  }
}

export default contactsImport
