/**
 * This file was migrated from app/assets/javascripts/controllers/concerns/batch_editing.js
 * It is used in the index action of the estimates, invoices, jobs, quickbooks, xero paloma controllers
 */
import pluralize from "pluralize"
import { addAccountSlug } from "../../../utils"

// TODO: jQuery should come from the jquery package instead of the global instance
const $ = window.$
// import $ from "jquery"

function batchEditing() {
  $('a[data-toggle="tab"]').on("show.bs.tab", function(e) {
    var activeTab = $(e.target).attr("href")
    toggleSelectActions($(activeTab).find(".select-all-toggle"))
  })

  $(document).on("click", ".select-all-toggle", function() {
    $(this)
      .parents("table")
      .find('input[type="checkbox"]')
      .prop("checked", isChecked(this))
    toggleSelectActions($(this))
  })

  $(document).on(
    "click",
    '.select-all-tab tbody input[type="checkbox"]',
    function() {
      toggleSelectActions($(this))
    }
  )

  $(document).on("keyup", ".confirm_delete", function() {
    if ($(this).val() === "DELETE" || $(this).val() === "delete") {
      $(this)
        .closest(".modal")
        .find(".btn-danger")
        .removeAttr("disabled")
    } else {
      $(this)
        .closest(".modal")
        .find(".btn-danger")
        .attr("disabled", "disabled")
    }
  })

  $(".confirm_delete_modal").on("hide.bs.modal", function() {
    $(this)
      .find(".confirm_delete")
      .val("")
      .keyup()
  })

  $(document).on("click", ".generate-multiple", function() {
    $("input.confirm_generate").val("true")
  })

  $(document).on("hide.bs.modal", ".confirm_generate_modal", function() {
    $("input.confirm_generate")
      .val("")
      .keyup()
  })

  $(document).on("click", ".send-invoices", function(event) {
    $("#invoice-summary-modal").modal("show")
    event.preventDefault() // Temporary until form submit works via AJAX
  })

  $(document).on("click", ".mark-paid", function() {
    mark_paid()
  })

  $(document).on("click", ".mark-sent", function() {
    mark_sent()
  })

  $(document).on("click", ".batch-print", function() {
    batch_print()
  })

  $(document).on("click", ".batch-update-jobs-btn", function() {
    if (
      $(this)
        .prev()
        .children(".job-status-select")
        .val() === "complete"
    ) {
      var jobIds = $(".listing-table-selector input:checkbox:checked:visible")
        .map(function() {
          return parseInt($(this).val())
        })
        .get()
      window.Concerns.prompt_complete_work_events(
        jobIds,
        function() {
          $(".tab-pane.active .batch-update-jobs-form").submit()
        },
        function() {
          $(".tab-pane.active .batch-update-jobs-form").submit()
        }
      )
      return false
    }
  })

  function isChecked(checkboxClass) {
    return $(checkboxClass).is(":checked")
  }

  function toggleSelectActions(selectAllCheckbox) {
    var selectedCount = selectAllCheckbox
      .parents("table")
      .find('tbody input[type="checkbox"]:checked').length
    var selectedInputs = selectAllCheckbox
      .parents(".select-all-tab")
      .find(".selected_inputs")
    selectedInputs.html("")
    if (selectedCount == 0) {
      selectAllCheckbox
        .parents(".select-all-tab")
        .find(".select-actions")
        .addClass("hide")
    } else {
      selectAllCheckbox
        .closest("table")
        .find('tbody input[type="checkbox"]:checked')
        .clone(true)
        .addClass("hide")
        .appendTo(selectedInputs)
      selectAllCheckbox
        .parents(".select-all-tab")
        .find(".select-actions")
        .removeClass("hide")
      $(".select-actions")
        .find('input[type="submit"]')
        .val("Apply to " + selectedCount + " selected")
      $(".invoice-actions .remove-invoice")
        .find(".ladda-label")
        .html(
          "Remove " + selectedCount + " " + pluralize("invoice", selectedCount)
        )
    }
  }

  $(".toggle-payment-summary").click(function() {
    $("#payment-summary-modal").modal("show")
  })

  function mark_paid() {
    var ids = checked_boxes()
    $.ajax({
      url: "/invoices/batch_update.json",
      type: "POST",
      data: {
        ids: ids,
        attribute: "invoice_status",
        value: "paid"
      },
      success: function() {
        window.location.reload()
      }
    })
  }

  function mark_sent() {
    var ids = checked_boxes()
    $.ajax({
      url: "/invoices/batch_update.json",
      type: "POST",
      data: {
        ids: ids,
        attribute: "invoice_sent_date"
      },
      success: function() {
        window.location.reload()
      }
    })
  }

  function batch_print() {
    var job_ids = checked_boxes()
    if (job_ids && job_ids.length > 0) {
      var pdf_url = addAccountSlug(
        "/invoices/batch_print.pdf?mode=inline&jobs=" +
          encodeURIComponent(job_ids)
      )
      window.location.replace(pdf_url)
    } else {
      alert("Please select one or more jobs to print.")
    }
  }

  function checked_boxes() {
    var check_boxes = $(".listing input:checked")
    var ids = []
    check_boxes.each(function() {
      ids.push($(this).val())
    })
    return ids
  }
}

export default batchEditing
