import $ from "jquery"
import { accountUrl } from "../../../utils/window_utils"

export function bindInboxConversation() {
  const condensedMessages = $(".condensed-messages")

  condensedMessages.on("click", function(e) {
    $(this).removeClass("condensed-messages")
    condensedMessages.off("click")
  })

  bindMessageClick()

  $(".js-archive-submit").on("click", function(e) {
    e.preventDefault()
    setUrl("/inbox/conversations/archive")
    $("#conversation_ids_form")
      .find("#redirect_to")
      .val(accountUrl(selfServiceOrAccount()))
    submitForm()
  })

  $(".js-unarchive-submit").on("click", function(e) {
    e.preventDefault()
    setUrl("/inbox/conversations/unarchive")
    $("#conversation_ids_form")
      .find("#redirect_to")
      .val(accountUrl(selfServiceOrAccount()))
    submitForm()
  })

  $(".js-mark-unread-submit").on("click", function(e) {
    e.preventDefault()
    setUrl("/inbox/conversations/mark_unread")
    $("#conversation_ids_form")
      .find("#redirect_to")
      .val(accountUrl(selfServiceOrAccount()))
    submitForm()
  })

  function setUrl(url) {
    if (window.location.href.indexOf("self_service") > -1) {
      url = "/self_service" + url
    }
    $("#conversation_ids_form").prop("action", accountUrl(url))
  }

  function selfServiceOrAccount() {
    if (window.location.href.indexOf("self_service") > -1) {
      return "/self_service/inbox"
    }
    return "/inbox"
  }
  function bindMessageClick() {
    $(".inbox-message").on("click", function() {
      if (
        $(this).hasClass("last") ||
        $(this).parents(".condensed-messages").length > 0
      ) {
        return
      }
      $(this).toggleClass("open")
    })
  }

  function submitForm() {
    $("#conversation_ids_form").submit()
  }
}

export default bindInboxConversation
